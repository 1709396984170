.checkin-page {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: pink; */
    background-image: url('../../../images/wood.jpg');
}

.checkin-page .checkin-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    width: 70%;
    border: 3px solid gray;
    padding: 30px;
    border-radius: 50px;
    background-color: white;
}

.checkin-page .name-results {
    display: flex;
    flex-direction: column;
    height: 250px;
    overflow: scroll;
    padding: 10px;
    border: 2px solid gray;
    border-radius: 15px;
}

.checkin-page .name-results .user-info {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: 0.25s all ease-in-out;
}

.checkin-page .checkin {
    margin: auto;
    width: 100%;
}

.checkin-page input,
.checkin-page select,
.checkin-page button,
.checkin-page textarea {
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    font-size: 24pt;
    background-color: white;
    border-radius: 15px;
    border: 2px solid gray;
    transition: all 0.5s ease-out;
}

.checkin-page input:hover,
.checkin-page select:hover,
.checkin-page button:hover,
.checkin-page textarea:hover {
    box-shadow: 2px 2px 4px #b8bbc2;
    transition: all 0.5s ease-out;
}

.checkin-page button {
    width: 35%;
    position: relative;
    text-align: center;
    display: block;
    background-color: #333333;
    color: lightgray;
    border-color: lightgray;
}

.checkin-page .buttons {
    display: flex;
}

.checkin-page .checkin-button {
    float: right;
    margin-left: auto;
    margin-right: 40px;
}

.checkin-page .back-button {
    margin-left: 40px;
    margin-right: auto;
}

.checkin-page .user-info {
    display: flex;
}

.checkin-page .user-name {
    float: left;
    margin-right: auto;
}

.checkin-page .user-birthdate {
    float: right;
    margin-left: auto;
}

.selected {
    background-color: #e6eeff;
    transform: scaleY(1.1);
    transform: scaleX(1.01);
    box-shadow: 0px 0px 4px lightgray;
    transition: 0.25s all ease-in-out;
}
