.rfid-scans {
    width: 100%;
    height: 100%;
}

.user-scans {
    width: 50%;
    height: 100%;
}

.overall-div {
    display: flex;
    height: 100%;
    width: 100%;
}

.rfid-scan-icon img {
    width: 40%;
    margin: auto;
    display: flex;
}

.scan-now-text p {
    font-family: "Oswald", sans-serif;
    color: gray;
    font-weight: 600;
    display: flex;
    font-size: 24pt;
    text-align: center;
    justify-content: center;
}

.scan-status-text p {
    font-family: "Oswald", sans-serif;
    color: gray;
    font-weight: 600;
    display: flex;
    font-size: 24pt;
    text-align: center;
    justify-content: center;
}

.scan-container {
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
}