.skatePunches-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Oswald', sans-serif;
  width: 100%;
}

.skatePunches-page .container {
  width: 100%;
}

.skatePunches-page .table {
  font-family: 'Oswald', sans-serif;
  font-size: large;
  margin-bottom: 0rem !important;
  /* table-layout: fixed; */
}

.my-custom-scrollbar {
  position: relative;
  height: 400px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.skatePunches-page .model-header {
  padding-top: 7px;
}
