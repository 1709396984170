.keypad {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
}

.pin {
    display: flex;
    flex-direction: row;
    margin: 10px 0 5px 0;
    transition: all 0.35s ease-out;
}

.pinCircle {
    background: rgb(32, 32, 32);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid rgb(143, 189, 216);
    margin: 10px;
    transition: all 0.35s ease-out;
}

.numpad {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 500px;
}

.numpad button {
    display: inline-block;
    border-radius: 50px;
    background-color: white;
    width: 100px;
    font-size: 2vw;
    height: 100px;
    border: 4px solid gray;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin: auto;
    font-size: 25pt;
    transition: all 0.35s ease-out;
}

.numpad button:focus {
    outline: 0;
}

.numpad button:active {
    background-color: rgb(143, 189, 216);
    border: 4px solid rgb(41, 138, 194);
}

.keypad .alt-button {
    font-size: 18pt;
    width: 100px;
    height: 100px;
    background: none;
    border: none;
    color: white;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.keypad .log-out {
    display: inline-block;
    border-radius: 20px;
    background-color: white;
    width: 150px;
    font-size: 2vw;
    height: 60px;
    border: 3px solid gray;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 15px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 20pt;
    transition: all 0.5s ease-out;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
