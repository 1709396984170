.login-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
}

.login-container img {
    z-index: 1;
    width: 20%;
    height: auto;
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-container .login {
    margin: auto;
    display: flex;
    /* position: absolute; */
    width: 690px;
    height: auto;
    min-height: 400px;
    /* max-height: 450px; */
    /* height: 500px; */
    border: 3px solid gray;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    flex-direction: column;
}

.login-container select:invalid {
    color: gray;
}

.login-container .inputs {
    margin-right: 5px;
    margin-left: 5px;
}

.login-container input,
.login-container select {
    padding: 20px;
    width: 100%;
    /* margin-bottom: 20px; */
    margin-top: 15px;
    display: flex;
    position: relative;
    box-sizing: border-box;
    font-size: 24pt;
    background-color: white;
    border-radius: 20px;
    border: 3px solid gray;
    transition: all 0.5s ease-out;
}

.login-container input:hover,
.login-container select:hover,
.login-container button:hover {
    box-shadow: 2px 2px 4px #b8bbc2;
    transition: all 0.5s ease-out;
}

.login-container .login-bottom-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.login-container .login-bottom-section button {
    display: inline-block;
    border-radius: 20px;
    background-color: white;
    width: 100%;
    font-size: 2vw;
    height: 60px;
    border: 3px solid gray;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 15px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 20pt;
    transition: all 0.5s ease-out;
}

.login-container .title {
    text-align: center;
    font-size: 36pt;
}

.login-container .error-text {
    color: red;
    font-size: 18pt;
    vertical-align: middle;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 25px;
    margin-bottom: 10px; */
    /* height: 100%; */
    font-family: 'Lato', sans-serif;
}

.login-container .show-error {
    margin-top: 15px;
    transition: all 0.4s;
}

.login-container .hide-error {
    margin-top: -10%;
    transition: all 0.4s;
}

.login-container .error-container {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: transparent;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

body {
    touch-action: manipulation;
}
