.admin-home-page {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-content: center;
  align-items: center;
  width: 100%;
  font-family: 'Oswald', sans-serif;
  font-size: large;
}

.admin-home-page .container {
  width: 100%;
  justify-content: start !important;
}

.admin-home-page .table {
  font-family: 'Oswald', sans-serif;
  font-size: large;
  margin-bottom: 0rem !important;
  /* table-layout: fixed; */
}

.my-custom-scrollbar {
  position: relative;
  height: 400px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.admin-home-page .model-header {
  padding-top: 7px;
}

.admin-home-page {
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../../images/adminbg.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.card-container {
  display: flex;
}

.jumbotron {
  height: 300px;
  width: 35%;
}

.et_pb_fullwidth_head_overlay {
  background-color: rgba(22, 22, 22, 0.62);
}