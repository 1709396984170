.popup-container {
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  transition: 0.25s all ease-in-out;
}

#content-spacer {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  text-align: center;
  width: calc(60vw - 40px);
  max-width: 500px;
  margin: auto;
  border-radius: 15px;
  border: 3px solid gray;
  padding: 10px;
  font-size: 18pt;
  font-family: 'Oswald', sans-serif;
  overflow: hidden;
}

.popup-button {
  width: calc(50% - 10px);
  border-radius: 15px;
  margin: 10px 5px 0px;
  font-size: 20pt;
  background-color: white;
  border: 3px solid gray;
  font-family: 'Oswald';
  font-weight: 400;
  text-transform: uppercase;
}

.hide-content {
  z-index: -999 !important;
  opacity: 0 !important;
  pointer-events: none !important;
  user-select: none !important;
}

.note-input {
  font-family: 'Oswald', sans-serif;
  width: 90%;
  height: 100px;
  border-radius: 7px;
  border-color: gray;
  border-width: 2px;
  font-size: 0.7em;
}
