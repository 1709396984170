.feedItem {
  background-color: white;
  border-radius: 15px;
  border: 3px solid gray;
  padding: 10px;
  /* margin: 10px; */
  margin-bottom: 10px;
  overflow: hidden;
  /* animation-name: grow;
    animation-duration: 1s;
    animation-timing-function: ease-in-out; */
}

.feedItemContentContain {
  display: flex;
  height: auto;
  width: 100%;
}

.feedItemTextContain {
  width: 100%;
  font-family: 'Lato', serif;
}

.feedItemHeader {
  /* font-weight: bold; */
  font-size: 18pt;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.feedItemContentMessage {
  padding: 0;
  margin: 0;
  margin-left: 25px;
  font-size: 14pt;
}

.feedItemIndicator {
  align-items: center;
  /* justify-content: flex-end; */
  width: auto;
  max-height: 83px;
  max-width: 83px;
  margin: auto;
}

.feedItemIndicator img {
  width: 100%;
  float: right;
}

.feedItemButtonContain {
  overflow: hidden;
}

.feedItemButtonsContainer {
  display: flex;
}

.feedItemButtonsHide {
  margin-top: -57px;
  transition: all 0.5s;
  opacity: 0;
}

.feedItemButtonsShow {
  visibility: visible;
  transition: all 0.5s;
  opacity: 1;
}

.feedItemButton {
  width: calc(100% - 10px);
  height: calc(14.1666666% - 10px);
  border-radius: 15px;
  margin: 10px 5px 0px 5px;
  font-size: 20pt;
  background-color: white;
  border: 3px solid gray;
  font-family: 'Oswald';
  font-weight: 400;
  text-transform: uppercase;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #d91a1a;
  border-radius: 50%;
}

.feedItemIndicator .dot {
  position: absolute;
}

#flag {
  border-radius: 50%;
  width: 81px;
  height: 81px;
}

.invertFlag {
  -webkit-filter: invert(1);
  filter: invert(1);
}

@keyframes grow {
  from {
    max-height: 0;
  }

  to {
    max-height: 100%;
  }
}
