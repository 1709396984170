.users-modal-body {
  height: 400px;
  overflow: auto;
}
.eventAttendance-modal-body {
  height: 50%;
  max-height: 200px;
  overflow-x: hidden;
}
.comment-status {
  font-size: 0.9rem;
  padding-top: 5px;
  width: 100%;
}
.flag-style {
  width: 35%;
  border-radius: 5px;
  padding-left: 6px;
}
.note-flag-style {
  margin-left: 3px;
  padding: 0 2px;
  border-radius: 5px;
}
.select-hidden-style {
  width: 6rem;
  text-align: center;
  border-radius: 0 3px 3px 0;
  border-color: lightgray;
}
