.page-contain {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: 700px;
    min-width: 375px;
    max-height: 100vh;
    max-width: 100vw;
    /* background-color: pink; */
    /* background-image: url('../../../images/wood.jpg'); */
    background: repeating-linear-gradient(45deg,
            #1b1b1b,
            #1b1b1b 10px,
            #141414 10px,
            #141414 20px);
    font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;
}

.page-content {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
    display: inline-block;
    max-width: 1024px;
    max-height: 768px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}