.singleFeed {
    border: 3px solid gray;
    border-radius: 20px;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    background-color: lightgray;
    height: calc(100% - 20px);
    width: 95%;
    margin: 0 auto;
}

.singleFeedContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.singleFeed h1 {
    margin: auto;
    font-size: 16pt;
    font-weight: bold;
    color: #777777;
    justify-content: center;
    text-align: center;
    margin-top: 25px;
}
