.system-users-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Oswald', sans-serif;
  width: 100%;
}

.system-users-page .container {
  width: 100%;
}

.system-users-page .table {
  font-family: 'Oswald', sans-serif;
  font-size: large;
}

.system-users-page .model-header {
  padding-top: 7px;
}

/* .system-users-page .system-users-container {
  background-color: white;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 20px;
  padding: 30px;
  border: 3px solid gray;
}

.system-users-container .system-users-list {
  margin: auto;
  width: 100%;
}

.system-users-container .system-users-header {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: auto;
  justify-content: center;
  text-align: center;
}

.system-users-container .system-users-header div {
  flex: 1;
  margin: auto;
  text-align: center;
  font-size: 16pt;
  font-weight: bold;
  margin-bottom: 10px;
}

.system-users-container .system-users-results {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 0px;
}

.system-users-container input {
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  font-size: 16pt;
  background-color: white;
  border-radius: 15px;
  border: 2px solid gray;
  transition: all 0.5s ease-out;
}

.system-users-container .system-users-results .employee-info {
  padding: 5px;
  border: 1px solid gray;
  border-radius: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: 0.25s all ease-in-out;
  display: flex;
  flex: 1;
}

.system-users-container .system-users-results .employee-info div {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 33.33%;
  flex: 1;
  font-size: 13pt;
}

.system-users-container .system-users-results .employee-info button {
  background-color: #eee;
  border: 1px solid gray;
  border-radius: 0px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12pt;
  margin: auto;
  width: 33.33%;
  flex: 1;
  max-width: 80px;
}

.system-users-buttons {
  display: flex;
}

.system-users-container .system-users-buttons button {
  padding: 15px;
  margin-top: 20px;
  position: relative;
  box-sizing: border-box;
  font-size: 18pt;
  border-radius: 0px;
  border: 2px solid gray;
  transition: all 0.5s ease-out;
  width: 25%;
  position: relative;
  text-align: center;
  display: block;
  background-color: lightgray;
  color: black;
  border-color: gray;
  margin-left: auto;
} */
