.buttonColumnContain {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin: 10px 0 0 0;
    overflow: scroll;
}

.buttonColumnImageContain {
    width: 100%;
    height: calc(15% - 5px);
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonColumnImageContain img {
    width: 80%;
    /* margin-right: 5px; */
}

.buttonColumnButton {
    width: 100%;
    max-height: 15%;
    margin: 5px 0;
    border-radius: 15px;
    font-size: 24pt;
    background-color: white;
    border: 3px solid gray;
    font-family: 'Oswald';
    font-weight: 400;
    text-transform: uppercase;
}