.activityFeed {
    padding: 10px;
    border: 3px solid gray;
    border-radius: 0 0 20px 20px;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    background-color: lightgray;
    height: calc(100% - 98px);
    border-top: none;
}

.container {
    height: 100%;
    width: 100%;
}

.feedButtons {
    display: flex;
    flex-direction: row;
    background-color: lightgray;
    border-radius: 20px 20px 0 0;
    border: 3px solid gray;
    border-bottom: none;
    height: 65px;
    font-size: 20pt;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.5s ease-in-out;
    padding-bottom: 3px;
}

.feedButtons .leftTab,
.feedButtons .rightTab {
    width: 50%;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    align-content: center;
    line-height: auto;
    height: 65px;
    margin-top: auto;
    position: relative;
    vertical-align: middle;
}

/* .feedButtons .leftTab div,
.feedButtons .rightTab div {
    margin: auto;
} */

/* 
.feedButtons .leftTab,
.feedButtons .rightTab {
    height: 65px;
    font-size: 20pt;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    border-bottom: none;
    background-color: lightgray;
    margin: auto;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;

}

.feedButtons .activityItems,
.feedButtons .newsItems {
    margin: auto;
    display: flex;
    flex-direction: row;

}

.feedButtons {
    width: 100%;
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0px;
} */

.activityFeed h1 {
    margin: auto;
    font-size: 16pt;
    font-weight: bold;
    color: #777777;
    justify-content: center;
    text-align: center;
    margin-top: 25px;
}

/* .rightTab {
    border-radius: 0 20px 0 0 !important;
}

.leftTab {
    border-radius: 20px 0 0 0 !important;
} */

.tallyCircle {
    height: 100%;
    width: 100%;
    background-color: rgb(184, 34, 34);
    border-radius: 50%;
    /* display: inline-block; */
    margin: auto;
    color: white;
    font-size: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 0px solid white;
    /* opacity: 75%; */
    position: relative;
    display: flex;
}

.circleContainer {
    /* height: 100%; */
    /* margin: auto; */
    /* margin-left: 3px; */
    position: absolute;
    /* margin-bottom: 22px; */
    top: 7px;
    right: 10px;
    height: 20px;
    width: auto;
    min-width: 20px;
}

.activityItems,
.newsItems {
    display: flex;
    flex-direction: row;
    width: auto;
    margin: auto;
}