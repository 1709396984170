*:focus {
    outline: none;
}

.item-info-container {
    display: flex;
    height: 8%;
}

.item-info {
    display: flex;
    width: 50%;
    margin: 5px;
    justify-content: center;
    color: black;
    font-size: 20pt;
}

.category-label {
    display: flex;
    color: black;
    font-size: 20pt;
    margin: 5px;
    height: 8%;
    min-height: 50px;
    padding-top: 10px;
    justify-content: center;
    border-top: 3px solid gray;
}

.status-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 45%;
    margin: auto;
}

.status-box {
    display: flex;
    margin: 5px 10% 5px 10%;
    height: 100%;
    width: 80%;
}

.status-container button {
    width: 30%;
    height: 100%;
    background-color: white;
    border: 3px solid gray;
    border-radius: 20px;
    position: relative;
}

.status-container button.selected {
    background-color: #e8ddb7;
    box-shadow: 2px 2px 4px grey;
    transform: translate(-2px, -2px);
    transition: all .2s ease-out;
}

.status-container .middle-button {
    margin-right: 5%;
    margin-left: 5%;
}

.top {
    top: 15px;
    width: 50%;
    left: 25%;
}

.bottom {
    bottom: 15px;
    width: 90%;
    left: 5%;
}

.button-text {
    font-size: 1.1rem;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
}

.comment-container {
    display: flex;
    margin-top: 2%;
    flex-direction: column;
    width: 100%;
    height: 43.5%;
}

.comment-box {
    font-size: 20pt;
    height: 100%;
    overflow: scroll;
    display: flex;
    margin: 5px;
    padding: 0 15px;
    border: 3px solid gray;
    border-radius: 20px;
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
}