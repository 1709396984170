.admin-page {
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../../images/adminbg.jpg');
  background-size: cover;
  background-attachment: fixed;
  padding: 20px, 0px;
  box-sizing: border-box;
}

.admin-page .title-container {
  text-align: center;
  color: #ccc;
}

.navbar {
  min-height: 80px;
  font-size: 25px;
  font-weight: 100;
  background-color: antiquewhite;
}

.navbar-brand {
  padding: 0 15px;
  height: 80px;
  line-height: 80px;
  font-size: 50px;
}

.admin-page .navbar-brand .img {
  padding-bottom: 15px;
}

.navbar-toggle {
  /* (80px - button height 34px) / 2 = 23px */
  margin-top: 23px;
  padding: 9px 10px !important;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    /* (80px - line-height of 27px) / 2 = 26.5px */
    padding-top: 26.5px;
    padding-bottom: 26.5px;
    line-height: 27px;
  }
}
h1,
.navbar-brand,
.navbar-nav a {
  font-family: 'Oswald', sans-serif;
}

.admin-page button {
  display: flex;
  position: relative;
  box-sizing: border-box;
  font-size: 16pt;
  border-radius: 0px;
  border: 2px solid gray;
  transition: all 0.5s ease-out;
  position: relative;
  text-align: center;
  display: block;
  background-color: #333333;
  color: lightgray;
  border-color: lightgray;
  margin-left: auto;
  margin-right: auto;
}

.admin-page button:active {
  background-color: #ccc;
}

.admin-page .admin-content {
  background-color: white;
  border: 1px solid gray;
  height: 95%;
  position: relative;
  margin: auto;
}

.admin-page .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-content: center;
}

.card {
  margin-bottom: auto;
  width: 500px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.card-header h3 {
  color: white;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

@media (max-width: 991px) {
  .navbar-nav {
    height: calc(100vh - 80px);
    overflow-y: scroll;
    transition: 0.3s ease-in-out all;
  }
  .navbar-custom::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .navbar-custom {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .navbar-toggler {
    width: 66px;
    height: 48px;
    border-color: black;
    outline: none !important;
    background: transparent !important;
  }

  .navbar-toggler:active {
    background-color: transparent !important;
  }

  .navbar-toggler:focus {
    outline: none !important;
    background: transparent !important;
  }

  .navbar-toggler .icon-bar {
    background-color: black;
    transform: rotate(0deg) translate(0px, 0px);
    transition: ease all 0.2s;
  }

  .navbar-toggler .icon-bar {
    display: block;
    width: 40px;
    height: 2px;
    border-radius: 1px;
  }

  .navbar-toggler .icon-bar + .icon-bar {
    margin-top: 10px;
  }

  .navbar-toggler:not(.collapsed) .icon-bar:nth-child(1) {
    transform: rotate(45deg) translate(9px, 9px);
    transition: ease all 0.2s;
  }

  .navbar-toggler:not(.collapsed) .icon-bar:nth-child(2) {
    opacity: 0;
    transition: ease all 0.2s;
  }

  .navbar-toggler:not(.collapsed) .icon-bar:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -8px);
    transition: ease all 0.2s;
  }

  .bootstrap-iso .dropdown-menu {
    outline: none !important;
  }

  .bootstrap-iso .nav-link {
    padding: 0.3rem 1rem;
  }
}
