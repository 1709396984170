.coffeePageImageContain {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.theBayLogo {
    max-height: 100%;
}

.coffeePageColumn {
    width: 50%;
    height: 100%;
    float: left;
}

.homeFeed {
    height: calc(100% - 50px);
}

.buttonContainer{
    display: flex;
    height: calc(12% - 10px);
    max-width: 900px;
    justify-content: center;
    margin: auto;
}

.button {
    height: 100%;
    width: 100%;
    max-width: 350px;
    margin: 5px auto;
    border-radius: 15px;
    font-size: 24pt;
    background-color: white;
    border: 3px solid gray;
    font-family: 'Oswald';
    font-weight: 400;
    text-transform: uppercase;
}
