.eventsPageImageContain {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.theBayLogo {
    max-height: 100%;
}

.eventsPageColumn {
    width: 50%;
    height: 100%;
    float: left;
}

.homeFeed {
    height: calc(100% - 50px);
}

.attendanceText {
    height: 100px;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
}

.eventText {
    height: 50px;
    max-height: 50px;
    width: 100%;
    max-width: 100%;
}

.feedContainer {
    height: calc(100% - 100px)
}

.eventsButtonColumnContain {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin: 10px 0 0 0;
    overflow: scroll;
}

.eventsButtonColumnImageContain {
    width: 100%;
    height: calc(15% - 5px);
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventsButtonColumnButton {
    width: 100%;
    height: calc(13.3% - 10px);
    max-height: 15%;
    margin: 5px 0;
    border-radius: 15px;
    font-size: 24pt;
    background-color: white;
    border: 3px solid gray;
    font-family: 'Oswald';
    font-weight: 400;
    text-transform: uppercase;
}

.attendanceButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(15.9% - 10px);
    max-height: 20%;
    padding: 5px 0;
    border-radius: 15px;
    font-size: 24pt;
    background-color: transparent;
    border-color: transparent;
    color: white;
    /* border: 3px solid gray; */
    font-family: 'Oswald';
    font-weight: 400;
    text-transform: uppercase;
}

.tallyChange {
    margin: auto 0;
    width: 90px;
    height: 90px;
    border-radius: 15px;
    background-color: white;
    border: 3px solid gray;
    font-size: 30px;
}
